.header {
    background-color: #D6BDA5;
    padding: 20px;
    color: white;
    text-align: center;
  }
  
  .header nav ul {
    list-style: none;
    padding: 0;
  }
  
  .header nav ul li {
    display: inline;
    margin: 0 10px;
  }
  
  .header nav ul li a {
    color: white;
    text-decoration: none;
  }
  
  .header nav ul li button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 1rem;
    padding: 0;
  }
  
  .header nav ul li button:hover {
    text-decoration: underline;
  }
  