.feedback-list {
  padding: 8px;
}

.filter-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 8px;
}

.filter-item {
  margin-right: 20px;
}

.filter-item label {
  margin-right: 10px;
}

.filter-dropdown {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.feedback-item {
  border: 1px solid #ccc;
  margin-bottom: 8px;
  overflow: hidden;
}

.feedback-title {
  background-color: #f0f0f0;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.feedback-title h2 {
  margin: 0;
  color: #333;
}

.status-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.status-dropdown {
  padding: 5px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 5px;
}

.status-info {
  font-size: 12px;
  color: #666;
  text-align: right;
}

.status-info p {
  margin: 2px 0;
}

.feedback-summary {
  background-color: white;
  padding: 16px;
  color: #666;
}

.feedback-divider {
  margin: 0;
  border: 0;
  border-top: 1px solid #eee;
}

.feedback-content {
  background-color: white;
  padding: 16px;
  color: #666;
}

.feedback-item .images {
  margin-top: 10px;
}

.feedback-item .images img {
  margin-right: 10px;
  margin-bottom: 10px;
}

.history-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.history-content {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  width: 80%;
  max-width: 600px;
  height: 500px;
  overflow-y: auto;
}

.login {
  max-width: 400px;
  margin: 0 auto;
  padding: 20px;
  border: none;
  border-radius: 5px;
}

.login form {
  display: flex;
  flex-direction: column;
}

.login form div {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.login form label {
  margin-bottom: 5px;
  font-weight: bold;
}

.login form input {
  padding: 8px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
}

.login button {
  padding: 10px;
  font-size: 16px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
  width: 100%;
}

.login-button {
  background-color: #a9a9a9;
}

.google-button {
  background-color: #D6BDA5;
}